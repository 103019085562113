import { createContext, useEffect, useState } from "react";
import UserService from "services/user.service";

export const ProfileContext = createContext();

const ProfileContextProvider = (props) => {
  const [profilesData, setProfilesData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      try {
        const res = await UserService.getProfile();
        setProfilesData(res.data.data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfile();
  }, [refresh]);

  return (
    <ProfileContext.Provider
      value={{
        profilesData,
        isLoading,
        isError,
        refresh,
        setRefresh,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
