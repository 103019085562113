import { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { routes, Loader } from "components";
import ProfileContextProvider from "contexts/ProfileContext";
import { getLayout } from "components/layouts";

const ProtectedRoute = () => (
  <Switch>
    <Suspense fallback={<Loader />}>
      {routes.map(({ component: Component, path, exact }) => (
        <Route path={`/${path}`} key={path} exact={exact}>
          <ProfileContextProvider>
            {getLayout(
            <Component />)}
          </ProfileContextProvider>
        </Route>
      ))}
      <Route exact path="/">
        <Redirect from="/" to="/home" />
      </Route>
    </Suspense>
  </Switch>
);

export default ProtectedRoute;
