function Button({ text }) {
  return (
    <button
      type="submit"
      className="w-full h-14 p-3 mt-16 mb-6 bg-pk-primary hover:shadow-lg text-white text-xl font-bold rounded-full ease-in-out duration-200 focus:outline-none"
    >
      {text}
    </button>
  );
}

export default Button;
