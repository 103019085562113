import { logo } from "assets";

function AuthLayout({ children }) {
  return (
    <section className="bg-login bg-pk-primary flex flex-col md:flex-row h-screen items-center">
      <img
        src={logo}
        alt="penakita logo"
        className="w-32 mt-16 md:mt-0 md:absolute md:left-32 md:top-14"
      />
      {children}
    </section>
  );
}

export default AuthLayout;
