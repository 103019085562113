import { lazy } from "react";

const routes = [
  {
    path: "home",
    component: lazy(() => import("pages/Dashboard")),
    exact: true,
  },
  {
    path: "profile",
    component: lazy(() => import("pages/Profile")),
    exact: true,
  },
  {
    path: "asset/blog/add",
    component: lazy(() => import("pages/AddBlog")),
    exact: true,
  },
  {
    path: "asset/blog/edit/:id",
    component: lazy(() => import("pages/AddBlog")),
    exact: true,
  },
  {
    path: "asset/instagram/add",
    component: lazy(() => import("pages/AddInstagram")),
    exact: true,
  },
  {
    path: "asset/instagram/edit/:id",
    component: lazy(() => import("pages/AddInstagram")),
    exact: true,
  },
  {
    path: "asset/twitter/add",
    component: lazy(() => import("pages/AddTwitter")),
    exact: true,
  },
  {
    path: "asset/twitter/edit/:id",
    component: lazy(() => import("pages/AddTwitter")),
    exact: true,
  },
  {
    path: "profile/blog/:id",
    component: lazy(() => import("pages/AssetBlog")),
    exact: true,
  },
  {
    path: "profile/instagram/:id",
    component: lazy(() => import("pages/AssetInstagram")),
    exact: true,
  },
  {
    path: "profile/twitter/:id",
    component: lazy(() => import("pages/AssetTwitter")),
    exact: true,
  },
  {
    path: "profile/edit",
    component: lazy(() => import("pages/EditProfile")),
    exact: true,
  },
  {
    path: "project",
    component: lazy(() => import("pages/Project")),
    exact: true,
  },
  {
    path: "project/detail/:id",
    component: lazy(() => import("pages/ProjectDetail")),
    exact: true,
  },
  {
    path: "myproject/detail/:projectId/task/:taskId/socialmedia/submit",
    component: lazy(() => import("pages/TaskSocialMedia")),
    exact: true,
  },
  {
    path: "myproject/detail/:projectId/task/:taskId/blog/submit",
    component: lazy(() => import("pages/TaskBlog")),
    exact: true,
  },
  {
    path: "myproject/detail/:projectId/task/:taskId",
    component: lazy(() => import("pages/Task")),
    exact: true,
  },
  {
    path: "myproject/detail/:projectId",
    component: lazy(() => import("pages/MyProjectDetail")),
    exact: true,
  },
  {
    path: "myproject",
    component: lazy(() => import("pages/MyProject")),
    exact: true,
  },
  {
    path: "balance",
    component: lazy(() => import("pages/Balance")),
    exact: true,
  },
  {
    path: "notifications",
    component: lazy(() => import("pages/Notifications")),
    exact: true,
  },
];

export default routes;
