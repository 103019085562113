export default function BalanceCard({ amount, isLoading }) {
  return (
    <div className="flex lg:w-80 h-16 lg:h-12 rounded-lg bg-white lg:bg-pk-primary shadow-main lg:shadow-none hover:shadow-secondary">
      <div className="flex items-center justify-between w-full p-4 space-x-4">
        <p className="text-sm text-pk-text lg:text-white">Balance</p>
        <div className="text-2xl text-pk-primary lg:text-white font-bold">
          {!isLoading && (
            <p>
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(amount)}
            </p>
          )}
          {isLoading && (
            <div className="w-24 animate-pulse overflow-hidden">
              <div className="w-full p-3 bg-gray-200 rounded"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
