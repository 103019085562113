function AuthMain({ children }) {
  return (
    <main className="w-full h-screen md:w-5/6 md:mx-auto flex items-center justify-center md:justify-end">
      <div className="w-max">
        <p className="hidden md:block mt-12 text-xl md:text-2xl text-white font-bold text-right">
          HELLO,
        </p>
        <p className="hidden md:block mt-1 text-3xl text-white font-bold text-right">
          WELCOME TO PENAKITA
        </p>
        {children}
      </div>
    </main>
  );
}

export default AuthMain;
