import { createTheme, styled } from "@nextui-org/react";

export const nextUiTheme = createTheme({
  type: "light",
  theme: {
    fonts: {
      sans: `"Roboto", "sans-serif"`,
    },
    colors: {
      mainColor: "#FF9D25",
    },
  },
});

export { styled };
