import axios from "axios";
import authHeader from "services/auth-header";

const login = (email, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/blogger/auth/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response) {
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem("token", JSON.stringify(response.data.data.token));
      }
      return response.data;
    });
};

const logout = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/blogger/auth/logout`, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response) {
        localStorage.clear();
      }
      return response.data;
    });
};

const forgot = (email) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/blogger/auth/forgot-password`, {
      email,
    })
    .then((response) => {
      return response;
    });
};

const reset = (token, password, password_confirmation) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/blogger/auth/reset-password`, {
      token,
      password,
      password_confirmation,
    })
    .then((response) => {
      return response;
    });
};

const registration = (
  name,
  email,
  password,
  password_confirmation,
  province_id,
  city_id
) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/blogger/auth/register`, {
      name,
      email,
      password,
      password_confirmation,
      province_id,
      city_id,
    })
    .then((response) => {
      return response;
    });
};

export {
  login,
  logout,
  forgot,
  reset,
  registration,
};
