// icons
export { default as logo } from "assets/images/logo.png";
export { default as charticon } from "assets/icons/charticon.svg";
export { default as editicon } from "assets/icons/editicon.svg";
export { default as workicon } from "assets/icons/workicon.svg";
export { default as menuicon } from "assets/icons/menuicon.svg";
export { default as CloseIcon } from "assets/icons/CloseIcon";
export { default as BurgerIcon } from "assets/icons/BurgerIcon";
export { default as notificon } from "assets/icons/notificon.svg";
export { default as notifwhiteicon } from "assets/icons/notifwhiteicon.svg";
export { default as profileicon } from "assets/icons/profileicon.svg";
export { default as profileorangeicon } from "assets/icons/profileorangeicon.svg";
export { default as profilewhiteicon } from "assets/icons/profilewhiteicon.svg";
export { default as editprofileicon } from "assets/icons/editprofileicon.svg";
export { default as addicon } from "assets/icons/addicon.svg";
export { default as emailicon } from "assets/icons/emailicon.svg";
export { default as passwordicon } from "assets/icons/passwordicon.svg";
export { default as showpwicon } from "assets/icons/showpwicon.svg";
export { default as hidepwicon } from "assets/icons/hidepwicon.svg";
export { default as paperorangeicon } from "assets/icons/paperorangeicon.svg";
export { default as workorangeicon } from "assets/icons/workorangeicon.svg";
export { default as shieldorangeicon } from "assets/icons/shieldorangeicon.svg";
export { default as chatgrayicon } from "assets/icons/chatgrayicon.svg";
export { default as dangergrayicon } from "assets/icons/dangergrayicon.svg";
export { default as dangerorangeicon } from "assets/icons/dangerorangeicon.svg";
export { default as dangerredicon } from "assets/icons/dangerredicon.svg";
export { default as editgrayicon } from "assets/icons/editgrayicon.svg";
export { default as imageicon } from "assets/icons/imageicon.svg";
export { default as imageorangeicon } from "assets/icons/imageorangeicon.svg";
export { default as filtericon } from "assets/icons/filtericon.svg";
export { default as searchicon } from "assets/icons/searchicon.svg";
export { default as iglogo } from "assets/icons/instagramicon.svg";
export { default as twlogo } from "assets/icons/twittericon.svg";
export { default as bloglogo } from "assets/icons/bloggericon.svg";
export { default as arrowleft } from "assets/icons/arrowleftorange.svg";
export { default as checklisticon } from "assets/icons/checklisticon.svg";
export { default as logouticon } from "assets/icons/logouticon.svg";

// portofolio / asset profile
export { default as blogduration } from "assets/icons/portofolio/blog-duration.svg";
export { default as blogpageview } from "assets/icons/portofolio/blog-pageview.svg";
export { default as blogtotalcontent } from "assets/icons/portofolio/blog-totalcontent.svg";
export { default as blogvisitor } from "assets/icons/portofolio/blog-visitor.svg";
export { default as igimpression } from "assets/icons/portofolio/ig-impression.svg";
export { default as igreach } from "assets/icons/portofolio/ig-reach.svg";
export { default as igshare } from "assets/icons/portofolio/ig-share.svg";
export { default as igtwcomment } from "assets/icons/portofolio/igtw-comment.svg";
export { default as igtwlike } from "assets/icons/portofolio/igtw-like.svg";
export { default as twretweet } from "assets/icons/portofolio/tw-retweet.svg";
export { default as assetdelete } from "assets/icons/portofolio/assetdeleteicon.svg";
export { default as assetedit } from "assets/icons/portofolio/assetediticon.svg";
export { default as optionicon } from "assets/icons/portofolio/optionicon.svg";

// images
export { default as bglogin } from "assets/images/login-bg.png";
export { default as bgregister } from "assets/images/register-bg.png";
export { default as projectimage } from "assets/images/project-default.png";
export { default as assetimage } from "assets/images/asset-default.png";
export { default as assetimagewhite } from "assets/images/asset-default-white.png";
export { default as noimage } from "assets/images/no-image.png";
