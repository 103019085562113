import { NavLink, Link } from "react-router-dom";
import { BalanceCard, SidebarMenu } from "components";
import { logo, logouticon } from "assets";
import { logout } from "services/auth.service";

function SidebarNav({ className }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userBalance = localStorage.getItem("userBalance");

  const handleLogOut = () => {
    logout().then(() => {
      window.location.replace("/");
    });
  };

  return (
    <>
      <nav
        className={`lg:h-[676px] p-4 shadow-xl rounded-2xl bg-pk-primary lg:sticky lg:top-0 lg:z-40 ${className}`}
      >
        <div>
          <div className="w-32 h-32 hidden lg:flex items-center mx-auto">
            <img src={logo} alt="Penakita" />
          </div>
          <nav>
            <ul>
              <li className="block lg:hidden rounded-lg lg:mt-4 mb-6">
                <Link to="/balance">
                  <BalanceCard
                    amount={
                      userBalance !== null ? userBalance : user?.total_balance
                    }
                  />
                </Link>
              </li>
              {SidebarMenu.map((item, i) => (
                <li className={`${item.classname} rounded-lg mb-2`} key={i}>
                  <NavLink
                    to={item.to}
                    className="flex items-center space-x-4 text-white px-4 py-4 rounded-lg hover:bg-pk-gray/20"
                    activeClassName="bg-pk-gray/30 text-white font-bold"
                  >
                    <img src={item.icon} alt="chart" />
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              ))}
              <li className="block lg:hidden rounded-lg mb-2">
                <button
                  className="flex items-center space-x-4 text-white px-4 py-4 rounded-lg"
                  onClick={handleLogOut}
                >
                  <img src={logouticon} alt="logout" />
                  <span>Log Out</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </nav>
    </>
  );
}

export default SidebarNav;
