/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "services/auth-header";

// General
async function fileUpload(formData) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CLIENT_URL}/uploads/v1/upload`,
      formData,
      {
        auth: {
          username: process.env.REACT_APP_CLIENT_USERNAME,
          password: process.env.REACT_APP_CLIENT_PASSWORD,
        },
      }
    );
    result = response.data;
  } catch (error) {
    throw error;
  }

  return result;
}

const getProvince = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/location/province?limit=50`
  );
};

const getCity = (provinceId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/location/city?province_id=${provinceId}&limit=50`
  );
};

const getDistrict = (cityId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/location/district?city_id=${cityId}&limit=100`
  );
};

const getSubDistrict = (districtId) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/location/sub-district?district_id=${districtId}&limit=100`
  );
};

const getNotifications = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/notifications?page=${page}&limit=${limit}`,
    {
      headers: authHeader(),
    }
  );
};

// Profile
const getProfile = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/blogger/profile`, {
    headers: authHeader(),
  });
};

const updateProfile = (
  name,
  username,
  phone,
  email,
  province_id,
  city_id,
  district_id,
  sub_district_id,
  address,
  bio,
  bank_id,
  bank_account_number,
  bank_account_name,
  photo_profile
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/profile`,
      {
        name,
        username,
        phone,
        email,
        province_id,
        city_id,
        district_id,
        sub_district_id,
        address,
        bio,
        bank_id,
        bank_account_number,
        bank_account_name,
        photo_profile,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// Asset
const getAssetList = (type) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/asset?page=1&limit&type=${type}`,
    {
      headers: authHeader(),
    }
  );
};

const getAssetDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const getAssetPost = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}/post`,
    {
      headers: authHeader(),
    }
  );
};

const createAssetBlog = (
  interest,
  type,
  url,
  name,
  year,
  total_content,
  visitor,
  viewer,
  duration,
  image
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset`,
      {
        interest,
        type,
        url,
        name,
        year,
        total_content,
        visitor,
        viewer,
        duration,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const createAssetInstagram = (interest, type, url, name, follower, image) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset`,
      {
        interest,
        type,
        url,
        name,
        follower,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const createAssetTwitter = (interest, type, url, name, follower, image) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset`,
      {
        interest,
        type,
        url,
        name,
        follower,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const createAssetPost = (
  id,
  url,
  post_date,
  impression,
  reach,
  like,
  comment,
  share,
  retweet,
  captions,
  image,
  is_brand
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}/post`,
      {
        url,
        post_date,
        impression,
        reach,
        like,
        comment,
        share,
        retweet,
        captions,
        image,
        is_brand,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const deleteAssetPost = (id, idPost) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}/post/${idPost}`,
    {
      headers: authHeader(),
    }
  );
};

const deleteAsset = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const updateAssetPost = (
  idPost,
  id,
  url,
  post_date,
  impression,
  reach,
  like,
  comment,
  share,
  retweet,
  captions,
  image,
  is_brand
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}/post/${idPost}`,
      {
        url,
        post_date,
        impression,
        reach,
        like,
        comment,
        share,
        retweet,
        captions,
        image,
        is_brand,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const editAssetBlog = (
  id,
  interest,
  type,
  url,
  name,
  year,
  total_content,
  visitor,
  viewer,
  duration,
  image
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}`,
      {
        interest,
        type,
        url,
        name,
        year,
        total_content,
        visitor,
        viewer,
        duration,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const editAssetInstagram = (id, interest, type, url, name, follower, image) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}`,
      {
        interest,
        type,
        url,
        name,
        follower,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const editAssetTwitter = (id, interest, type, url, name, follower, image) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/asset/${id}`,
      {
        interest,
        type,
        url,
        name,
        follower,
        image,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// Project
const getProjectList = (page, keyword, mediaType, industry, subType) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/project/master?page=${page}&limit=10&keyword=${keyword}&media_type=${mediaType}&industry=${industry}&sub_type=${subType}`,
    {
      headers: authHeader(),
    }
  );
};

const getProjectDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/project/master/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const postJoinProject = (id, asset_id) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/project/master/${id}`,
      { asset_id },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getMyProjectList = (page, status) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/project?page=${page}&limit=10&status=${status}`,
    {
      headers: authHeader(),
    }
  );
};

const getMyProjectDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/project/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const getTaskDetail = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/project/task/${id}`,
    {
      headers: authHeader(),
    }
  );
};

const createSubmitTask = (id, caption, content, url, images) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/project/${id}/send-task`,
      {
        caption,
        content,
        url,
        images,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const updateSubmitTask = (id, caption, content, url, images, idSubmit) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/project/${id}/send-task/${idSubmit}`,
      {
        caption,
        content,
        url,
        images,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

const getTaskFeedback = (id, idAssignor) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/project/${id}/send-task/${idAssignor}`,
    {
      headers: authHeader(),
    }
  );
};

const getEvidence = (idAssignor) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/account/project/task/evidence/${idAssignor}`,
    {
      headers: authHeader(),
    }
  );
};

const createEvidence = (
  assignorId,
  postLink,
  postDate,
  like,
  comment,
  share,
  impression,
  reach,
  visitor,
  totalPageView
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/blogger/account/project/task/evidence`,
      {
        assignorId,
        postLink,
        postDate,
        like,
        comment,
        share,
        impression,
        reach,
        visitor,
        totalPageView,
      },
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      return response;
    });
};

// Finance
const getBankList = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/finance/bank?limit=200`,
    {
      headers: authHeader(),
    }
  );
};

const getBalanceList = (page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/finance/history?limit=${limit}&page=${page}`,
    {
      headers: authHeader(),
    }
  );
};

const requestWithdraw = (amount) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/blogger/finance/history/request`,
    { amount },
    {
      headers: authHeader(),
    }
  );
};

// Dashboard
const getOverview = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/dashboard/overview`,
    {
      headers: authHeader(),
    }
  );
};

const getProjectProgress = () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/blogger/dashboard/project-progress`,
    {
      headers: authHeader(),
    }
  );
};

const getAnnouncements = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/blogger/announcements`, {
    headers: authHeader(),
  });
};

export default {
  fileUpload,
  getProvince,
  getCity,
  getDistrict,
  getSubDistrict,
  getNotifications,
  getProfile,
  updateProfile,
  getAssetList,
  getAssetDetail,
  getAssetPost,
  createAssetBlog,
  createAssetInstagram,
  createAssetTwitter,
  createAssetPost,
  updateAssetPost,
  deleteAssetPost,
  deleteAsset,
  editAssetBlog,
  editAssetInstagram,
  editAssetTwitter,
  getProjectList,
  getProjectDetail,
  postJoinProject,
  getMyProjectList,
  getMyProjectDetail,
  getTaskDetail,
  createSubmitTask,
  updateSubmitTask,
  getTaskFeedback,
  getEvidence,
  createEvidence,
  getBankList,
  getBalanceList,
  requestWithdraw,
  getOverview,
  getProjectProgress,
  getAnnouncements,
};
