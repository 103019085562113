export default function Footer() {
  return (
    <footer className="lg:py-4 font-light">
      <p className="text-sm lg:text-base">
        Copyright &copy; {new Date().getFullYear()}{" "}
        <a
          href="https://penakita.id"
          target="_blank"
          rel="noreferrer"
          className="text-blue-400 hover:text-blue-600"
        >
          PENAkita
        </a>
      </p>
    </footer>
  );
}
