import { useState } from "react";
import { Link } from "react-router-dom";
import { BalanceCard, SidebarNav, HeaderContentRight } from "components";
import { BurgerIcon, CloseIcon, logo } from "assets";

export default function Header({ title, userBalance, isLoading }) {
  const [navIsOpen, setNavIsOpen] = useState(false);

  return (
    <header className="bg-pk-primary lg:bg-white shadow-main rounded-2xl sticky top-0 z-40">
      <div className="flex items-center justify-between h-16">
        <div className="flex justify-start items-center lg:hidden">
          <button
            className="px-4 rounded-md text-white lg:hidden"
            onClick={() => setNavIsOpen((x) => !x)}
          >
            {navIsOpen ? <CloseIcon /> : <BurgerIcon />}
          </button>
        </div>
        <h6 className="text-white font-bold lg:hidden">{title}</h6>
        <div></div>
        <div className="hidden lg:block p-2">
          <Link to="/balance">
            <BalanceCard amount={userBalance} isLoading={isLoading} />
          </Link>
        </div>
        <HeaderContentRight />
      </div>
      {navIsOpen && (
        <div
          onClick={() => setNavIsOpen((x) => !x)}
          className="p-4 lg:hidden fixed inset-0 z-30 bg-black bg-opacity-20"
        >
          <div
            onClick={(event) => event.stopPropagation()}
            className="h-16 px-4 bg-pk-primary rounded-t-2xl flex items-center justify-between"
          >
            <button
              className="text-white lg:hidden"
              onClick={() => setNavIsOpen((x) => !x)}
            >
              <CloseIcon />
            </button>
            <div className="h-8 flex justify-end">
              <img src={logo} alt="Penakita" className="h-8" />
            </div>
          </div>
          <SidebarNav
            onClick={(event) => event.stopPropagation()}
            className={`rounded-t-none`}
          />
        </div>
      )}
    </header>
  );
}
