import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { profileicon, notificon } from "assets";
import { logout } from "services/auth.service";
import UserService from "services/user.service";
import { format, parseISO } from "date-fns";

function HeaderContentRight() {
  const handleLogOut = () => {
    logout().then(() => {
      window.location.replace("/");
    });
  };

  const [notifications, setNotifications] = useState([]);
  const [errorNotifications, setErrorNotifications] = useState(false);

  const fetchNotifications = async () => {
    try {
      const response = await UserService.getNotifications(1, 4);
      if (response.data.data.data.length > 0) {
        setNotifications(response.data.data.data);
      } else {
        setErrorNotifications(true);
      }
    } catch (err) {
      setErrorNotifications(true);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="hidden lg:flex justify-end items-center w-full">
      <div className="flex mr-2">
        <div className="py-4 px-2 flex items-center justify-center">
          <div
            className="group relative flex justify-center items-center focus:outline-none"
            tabIndex="0"
          >
            <button className="p-1 rounded-full hover:bg-pk-text-gray/50">
              <img src={profileicon} alt="profile" />
            </button>
            <nav
              tabIndex="0"
              className="border bg-white shadow-secondary invisible rounded w-auto absolute -left-10 top-full transition-all opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1 focus:outline-none"
            >
              <ul>
                <li>
                  <Link to="/profile">
                    <button className="w-24 px-4 py-2 rounded-t hover:bg-pk-primary hover:text-white focus:outline-none">
                      Profile
                    </button>
                  </Link>
                </li>
                <li>
                  <button
                    className="w-24 px-4 py-2 rounded-b hover:bg-pk-primary hover:text-white focus:outline-none"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="py-4 px-2 flex items-center justify-center">
          <div
            className="group relative flex justify-center items-center focus:outline-none"
            tabIndex="0"
          >
            <button className="p-1 rounded-full hover:bg-pk-text-gray/50">
              <img src={notificon} alt="notification" />
              <span className="animate-ping absolute top-1 right-2 block h-1 w-1 rounded-full ring-2 ring-green-400 bg-green-600"></span>
            </button>
            <nav
              tabIndex="0"
              className="border bg-white shadow-secondary invisible rounded w-64 absolute -right-4 top-full z-10 transition-all opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1 focus:outline-none"
            >
              <ul>
                {errorNotifications && (
                  <li className="p-4 text-center text-sm text-pk-error">
                    Tidak ada notifikasi.
                  </li>
                )}
                {notifications &&
                  notifications.map((data) => (
                    <li
                      className="flex flex-col space-y-1 px-4 py-3 hover:bg-pk-soft"
                      key={data.id}
                    >
                      <p className="text-sm font-bold">{data.message}</p>
                      <p className="text-xs">
                        {data?.createdAt &&
                          format(parseISO(data.createdAt), "d MMM y HH:mm")}
                      </p>
                    </li>
                  ))}
              </ul>
              {notifications && (
                <Link to="/notifications">
                  <button className="w-full bg-pk-text text-white text-center text-sm font-bold py-2 rounded-b">
                    Lihat semua notifikasi
                  </button>
                </Link>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderContentRight;
