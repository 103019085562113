import {
  charticon,
  workicon,
  editicon,
  profilewhiteicon,
  notifwhiteicon,
} from "assets";

export const SidebarMenu = [
  {
    title: "Dashboard",
    icon: charticon,
    to: "/home",
    selected: true,
  },
  {
    title: "Project List",
    icon: workicon,
    to: "/project",
    selected: false,
  },
  {
    title: "My Project",
    icon: editicon,
    to: "/myproject",
    selected: false,
  },
  {
    title: "Profile",
    icon: profilewhiteicon,
    to: "/profile",
    selected: false,
    classname: "block lg:hidden",
  },
  {
    title: "Notifications",
    icon: notifwhiteicon,
    to: "/notifications",
    selected: false,
    classname: "block lg:hidden",
  },
];
