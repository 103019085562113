import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  ProtectedRoute,
  PublicRoute,
  PrivateRoute,
  Loader,
  Alert,
} from "components";
import axios from "axios";

const LoginPage = lazy(() => import("pages/Login"));
const ForgotPassword = lazy(() => import("pages/Forgot"));
const ResetPassword = lazy(() => import("pages/Reset"));
const RegisterPage = lazy(() => import("pages/Register"));

const App = () => {
  const responseSuccessHandler = (response) => {
    return response;
  };

  const responseErrorHandler = (error) => {
    if (error.response.status === 401) {
      Alert.fire({
        title: "Sesi Anda Telah Berakhir",
        text: "Silahkan klik tombol ini untuk login kembali.",
        confirmButtonText: "LOGIN",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location = "/";
        }
      });
    }

    return Promise.reject(error);
  };

  axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  );

  // Check Auth
  const getAuth = () => {
    const auth = localStorage.getItem("isAuth");
    if (auth) {
      return auth;
    } else {
      return false;
    }
  };

  const isAuthenticated = getAuth();

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
            <LoginPage />
          </PublicRoute>
          <PublicRoute
            path="/forgot-password"
            isAuthenticated={isAuthenticated}
          >
            <ForgotPassword />
          </PublicRoute>
          <PublicRoute
            path="/password-reset/:token"
            isAuthenticated={isAuthenticated}
          >
            <ResetPassword />
          </PublicRoute>
          <PublicRoute exact path="/register" isAuthenticated={isAuthenticated}>
            <RegisterPage />
          </PublicRoute>
          <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
            <ProtectedRoute />
          </PrivateRoute>
          <Route path="*">
            <ProtectedRoute />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
