import { useContext } from "react";
import { Sidebar, SidebarNav, Header, Footer } from "components";
import { StyledLayoutContent } from "./styled";
import { ProfileContext } from "contexts/ProfileContext";

export const BaseLayout = ({ children }) => {
  const { profilesData, isLoading } = useContext(ProfileContext);

  return (
    <div className="pt-6 max-w-[1360px] container mx-auto">
      <Sidebar>
        <SidebarNav />
      </Sidebar>
      <StyledLayoutContent className="mx-4 mb-4 lg:ml-4 relative min-h-screen lg:max-w-[1130px]">
        <Header userBalance={profilesData?.total_balance} isLoading={isLoading} />
        <div className="">{children}</div>
        <Footer />
      </StyledLayoutContent>
    </div>
  );
};

export const getLayout = (page) => {
  return <BaseLayout>{page}</BaseLayout>;
};
