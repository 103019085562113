export default function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.295 5.705C17.9056 5.31564 17.2744 5.31564 16.885 5.705L13.1314 9.45863C12.7354 9.85465 12.5373 10.0527 12.309 10.1268C12.1082 10.1921 11.8918 10.1921 11.691 10.1268C11.4627 10.0527 11.2646 9.85465 10.8686 9.45863L7.115 5.705C6.72564 5.31564 6.09436 5.31564 5.705 5.705C5.31564 6.09436 5.31564 6.72564 5.705 7.115L9.45863 10.8686C9.85465 11.2646 10.0527 11.4627 10.1268 11.691C10.1921 11.8918 10.1921 12.1082 10.1268 12.309C10.0527 12.5373 9.85465 12.7354 9.45863 13.1314L5.705 16.885C5.31564 17.2744 5.31564 17.9056 5.705 18.295C6.09436 18.6844 6.72564 18.6844 7.115 18.295L10.8686 14.5414C11.2646 14.1454 11.4627 13.9473 11.691 13.8732C11.8918 13.8079 12.1082 13.8079 12.309 13.8732C12.5373 13.9473 12.7354 14.1454 13.1314 14.5414L16.885 18.295C17.2744 18.6844 17.9056 18.6844 18.295 18.295C18.6844 17.9056 18.6844 17.2744 18.295 16.885L14.5414 13.1314C14.1454 12.7354 13.9473 12.5373 13.8732 12.309C13.8079 12.1082 13.8079 11.8918 13.8732 11.691C13.9473 11.4627 14.1454 11.2646 14.5414 10.8686L18.295 7.115C18.6844 6.72564 18.6844 6.09436 18.295 5.705Z"
        fill="currentColor"
      />
    </svg>
  );
}
